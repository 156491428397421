@import "./colors.scss";

* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Fira Code', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #efefef;
  color: black;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$nav-height: 65px;



h1, h2 {
  text-align: center;
}
@media print {
  main {
    font-size: .8em;
  }
}

a {
  text-decoration: none;
  color: $indigo-dye;
  // cursor: default;
}

@media print {
  a, a:hover, a:visited {
    color: black;
  }
  body {
    font-size: .9em;
  }
}

@import "./Cmpt/Header.scss";
@import "./Pages/Resume.scss";
@import "./App.scss";

@media screen {
  .hide-screen {
    display: none;
  }
}
@media print {
  body {
    color: black !important;
    background: white !important;
    font-size: .85em;
  }
}