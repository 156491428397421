.resume {
  h1 {
    font-size: 3em;
    font-weight: bold;
  }
  h2.title {
    font-style: italic;
  }
  .contact-info {
    margin-top: .4em;
    font-size: 1.3em;
    display: flex;
    justify-content: center;
    ul {
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      li {
        margin: 0 .4em;
      }
      li:not(:first-child):before {
        content: " | ";
        margin-right: .4em;
      }
    }
  }
  h3 {
    font-family: Antic, sans-serif;
    margin-bottom: .2em;
    text-transform: uppercase;
    color: $indigo-dye;
  }
  .content {
    max-width: 900px;
    margin: 1.5em auto;
    font-size: 1.3rem;
    section {
      margin: 1rem 0;
    }
  }
  .skills {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(3, auto);
    grid-auto-flow: column;
    justify-items: space-around;
    margin: 0 2em;
    font-size: 1.1rem;
  }
  .lists {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 1em;
    ul {
      list-style-type: none;
    }
    .list {
      width: 100%;
    }
    p {
      font-style: italic;
    }
  }
  .company, .school {
    color: $indigo-dye;
    font-weight: bold;
  }
  .experience {
    h4 {
      font-size: 1rem;
      .company {
        font-size: 1.3rem;
      }
    }
      .title {
        font-size: 1.1rem;
        font-weight: normal;
        font-style: italic;
      }
      .duration {
        font-size: 1rem;
        font-style: italic;
        font-weight: normal;
      }
    .general {
      display: flex;
      justify-content: space-between;
    }
    margin-top: .9em;
    .company {
      color: $indigo-dye;
      font-weight: bold;
    }
  }
  .description {
    padding-left: 1rem;
    font-size: 1.1rem;
    font-weight: 500;
    li {
      margin-top: .2rem;
      list-style: none;
    }
    li:before {
      content:"·";
      font-size:24px;
      vertical-align:middle;
      line-height:1rem;
      padding-right: .5rem;
    }
  }
  .education {
    .general {
      display: flex;
      justify-content: space-between;
    }
    .degree {
        font-size: 1.1rem;
        font-weight: normal;
        font-style: italic;
    }
    .graduation, .major {
        font-size: 1rem;
        font-style: italic;
        font-weight: normal;
    }
    .major {
      margin-left: 1em;
    }
  }
}

.App-dark {
  .resume {
    .experience {
      .company {
        color: $celadon-blue;
      }
    }
  }
}

@media (max-width: 750px) {
  .resume {
    .skills {
      width: 100%;
      display:flex;
      flex-direction: column;
      align-content: center;
    }
  }
}

@media print {
  .resume {
    margin-top: 2em;
    .skills {
      display: grid;
      grid-template-columns: repeat(3, auto);
      grid-template-rows: repeat(3, auto);
      grid-auto-flow: column;
      justify-items: space-around;
      margin: 0 2em;
    }
  }
  * {
    color: black !important;
  }
}
