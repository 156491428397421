$indigo-dye: hsla(207, 50%, 29%, 1);
$celadon-blue: hsla(200, 44%, 75%, 1);
$sky-blue-crayola: hsla(187, 75%, 75%, 1);
$celeste: hsla(170, 74%, 82%, 1);

// light
$indigo-dye-light: hsla(207, 50%, 80%, 1);
$sky-blue-crayola: hsla(187, 75%, 95%, 1);

// dark
$indigo-dye-dark: hsla(207, 50%, 10%, 1);
$indigo-dye-dark-transparent: hsla(207, 50%, 10%, .9);
