.top-nav {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: $indigo-dye;
  padding: .2em;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $nav-height;
  box-sizing: border-box;
  .logo {
    font-size: 1.6em;
    padding: .3em;
    color: white;
    // font-family: 'Bellota', sans-serif;
  }
  .bars {
    color: white;
    padding: 1em;
    display: none;
  }
  .nav-links {
    display: flex;
    list-style-type: none;
    a, a:hover, a:visited {
      text-decoration: none;
      display: block;
      color: white;
    }
    a {
      display: flex;
      align-items: center;
      font-size: 1.2em;
      padding: .3em .8em;
    }
    span.link {
      text-decoration: none;
      background-color: transparent;
      display: flex;
      align-items: center;
      font-size: 1.2em;
      padding: .3em .8em;
      .nav-text {
        display: none;
      }
    }
    .nav-icon {
      display: flex;
      align-items: center;
      margin-right: .4em;
      color: white;
      cursor: pointer;
    }
    .nav-item {
      box-sizing: border-box;
      display: flex;
      align-items:center;
      justify-items: center;
      height: 32px;
    }
  }
}
@media screen and (max-width: 1200px) {
  .top-nav {
    .nav-item {
      max-width: 32px;
      overflow: hidden;
      transition: all 1.5s;
      white-space: nowrap;
      &:hover {
        max-width: 500px;
      }
    }
  }

}

@media screen and (max-width: 950px) {
  .top-nav {
    .hide {
      display: none;
    }
    .bars {
      display: block;
    }
    .nav-links {
      border-radius: 0px 0px 4px 4px;
      position: absolute;
      top: $nav-height;
      flex-direction: column;
      color: white;
      right: 0;
      background-color: $indigo-dye-dark;
      width: 100%;
      a {
        align-items: center;
        justify-items: center;
        width: 100%;
        .nav-item {
          .nav-text {
            display: block;
          }
        }
      }
      .nav-item {
        max-width: 1000px;
      }
    }
  }
}
.top-nav-dark {
  background-color: $indigo-dye-dark-transparent;
  .logo {
    color: white;
  }
}

@media print {
  .top-nav {
    display: none;
  }
}